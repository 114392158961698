import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import ICCAMCards01 from 'components/Software/iOS/ICCAM/iccamCard01';
import ICCAMCards02 from 'components/Software/iOS/ICCAM/iccamCard02';
import NavButtons from 'components/Software/iOS/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "iOS Software for your INSTAR IP Camera",
  "path": "/Software/iOS/ICCAM/",
  "dateChanged": "2017-12-05",
  "author": "Mike Polinowski",
  "excerpt": "The iOS app ICCAM for your iPad or iPhone. Control your camera when you don´t have access to your computer.",
  "image": "./P_SearchThumb_ICCAM.png",
  "social": "/images/Search/P_SearchThumb_ICCAM.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-iOS_ICCAM_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='iOS Software for your INSTAR IP Camera' dateChanged='2017-12-05' author='Mike Polinowski' tag='INSTAR IP Camera' description='The iOS app ICCAM for your iPad or iPhone. Control your camera when you don´t have access to your computer.' image='/images/Search/P_SearchThumb_ICCAM.png' twitter='/images/Search/P_SearchThumb_ICCAM.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/iOS/ICCAM/' locationFR='/fr/Software/iOS/ICCAM/' crumbLabel="ICCAM" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "ios",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ios",
        "aria-label": "ios permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`iOS`}</h2>
    <h3 {...{
      "id": "iccam",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#iccam",
        "aria-label": "iccam permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ICCAM`}</h3>
    <p>{`To access your IP camera with the `}<a href="https://itunes.apple.com/us/app/iccam/id883484539?mt=8" target="_blank" rel="noopener noreferrer">{`iOS App ICCAM`}</a>{` we recommend that you first download the newest version from the app store. Use the search word "ICCAM" to find the app inside the app store.`}</p>
    <h3 {...{
      "id": "add-a-p2p-camera-to-iccam-ios",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-a-p2p-camera-to-iccam-ios",
        "aria-label": "add a p2p camera to iccam ios permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a P2P Camera to ICCAM (iOS)`}</h3>
    <p>{`Launch ICCAM on your iPhone or iPad and press Add a new camera. Choose Search in LAN to scan for P2P cameras in your local network. Finalize the setup by giving your camera a name and add the password for your administrator account - then click Save.`}</p>
    <ICCAMCards01 mdxType="ICCAMCards01" />
    <h3 {...{
      "id": "access-your-p2p-cameras-live-stream-and-advanced-p2p-functions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#access-your-p2p-cameras-live-stream-and-advanced-p2p-functions",
        "aria-label": "access your p2p cameras live stream and advanced p2p functions permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Access your P2P cameras live stream and advanced P2P functions`}</h3>
    <p>{`The camera list will show you all connected cameras. Click on a camera to access the live video and advanced functions, like video or snapshot recording and audio streams.`}</p>
    <ICCAMCards02 mdxType="ICCAMCards02" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      